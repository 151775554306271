import React, { Suspense, lazy } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

// Lazy loading components
const Home = lazy(() => import('./components/home/Home'));
const About = lazy(() => import('./components/about/About'));
const Contact = lazy(() => import('./components/contact-us/Contact'));
const Dynamic_pages = lazy(() => import('./components/dynamic-page/Dynamic_pages'));
const Images = lazy(() => import('./components/media/Images'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const Staticpage = lazy(() => import('./components/static-pages/Staticpage'));
const CareerPage = lazy(() => import('./components/career/career'));
const JobDetails = lazy(()=> import('./components/career/JobDetails'));
function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Images />} />
            <Route path="contact" element={<Contact />} />
            <Route path="/page/:name" element={<Dynamic_pages />} />
            <Route path="/static/:id" element={<Staticpage />} />
            <Route exact path="career" element={<CareerPage />} />
            <Route exact path="job-description/:slug" element={<JobDetails />} />
            <Route path="/page-not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
